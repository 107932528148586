<template>
  <div class="row q-gutter-lg" v-if="currentSuiviIcEcheance && currentSuiviIcEcheance.details">
    <div class="col">
      <div>
        <h6 class="title-h6">{{$t('processes.synthese')}}</h6>
        <q-table wrap-cells
          flat
          :data="syntheseDatas"
          :columns="syntheseColumns"
          row-key="id"
          :rows-per-page-options="[0]"
          :pagination.sync="pagination"
          separator="cell"
          bordered
          hide-bottom>
          <q-tr slot="body" slot-scope="props" :props="props">
            <q-td class="cell-background-white" v-for="col in props.cols" :key="col.name" :props="props" :auto-width="col.autoWidth">
              <div v-if="col.value" v-html="col.value" />
            </q-td>
          </q-tr>
        </q-table>
      </div>
      <div v-if="currentSuiviIcEcheance.details.IARD">
        <h6 class="title-h6">{{$t('processes.iard')}}</h6>
        <q-table wrap-cells
          flat
          :data="iardDetailsDatas"
          :columns="iardSanteVieDetailsColumns"
          row-key="id"
          :rows-per-page-options="[0]"
          :pagination.sync="pagination"
          separator="cell"
          hide-bottom
          bordered
          hide-header>
          <q-tr slot="body" slot-scope="props" :props="props"
            class="cursor-pointer">
            <q-td v-for="col in props.cols" :key="col.name" :props="props" :auto-width="col.autoWidth">
              <div v-if="col.value" v-html="col.value" />
            </q-td>
          </q-tr>
        </q-table>
        <h3 class="title-echeancier">{{$t('processes.echeancierPartClassique')}}</h3>
        <q-table wrap-cells v-if="echeanceClassiqueFormData.IARD"
          flat
          :data="iardEcheanceClassiqueDatas"
          :columns="iardSanteVieEcheancesColumns"
          row-key="id"
          :rows-per-page-options="[0]"
          :pagination.sync="pagination"
          separator="cell"
          bordered
          hide-bottom>
          <q-tr slot="body" slot-scope="props" :props="props">
            <q-td class="cell-background-white" v-for="col in props.cols" :key="col.name" :props="props" :auto-width="col.autoWidth">
              <div v-if="col.value" v-html="col.value" />
            </q-td>
          </q-tr>
        </q-table>
        <h3 class="title-reglement">{{$t('processes.reglementPartClassique')}}</h3>
        <div class="row">
          <div class="col-2">Date</div>
          <div class="col-3">Description</div>
          <div class="col-2">Montant</div>
          <div class="col-2">Intérêts</div>
          <div class="col-2">Pourcentage</div>
          <div class="col-1"></div>
        </div>
        <div v-for="reglement in currentSuiviIcEcheance.details.IARD.reglements.classique" :key="reglement.id" class="row col-12 items-end">
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" :color="color"
            :value="$formatDate(reglement.date_paiement)" />
          <base-input-text class="col-3 q-pb-none" label="" :readonly="true" placeholder="Description" :color="color"
            :value="`${reglement.description || ''}`" ref="label" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="montant" :color="color"
            :value="`${parseFloat(reglement.montant || 0).toLocaleString()}€`" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="interets" :color="color"
            :value="`${parseFloat(reglement.interets || 0).toLocaleString()}€`" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="pourcentage" :color="color"
            :value="`${reglement.pourcentage}%`" />
          <q-icon class="col-1 q-pb-sm" name="delete_forever" @click="removeReglement('IARD', 'classique', reglement.id)" size="2rem" />
        </div>
        <div class="row col-12 items-end">
          <div class="fix-padding q-pb-none col-2 q-field--with-bottom">
            <base-input-date label="" placeholder="Date" :color="color"
              v-model="dateReglement.iard.classique" />
          </div>
          <base-input-text class="col-3 q-pb-none" label="" placeholder="Description" :color="color"
            v-model="descriptionReglement.iard.classique" />
          <base-input-text class="col-2 q-pb-none" label="" placeholder="Montant" :color="color"
            v-model="montantReglement.iard.classique" />
          <base-input-text class="col-2 q-pb-none" label="" placeholder="Interet" :color="color"
            v-model="interetReglement.iard.classique" />
          <div class="col-1"></div>
        </div>
        <div class="row justify-end">
          <div class="col col-3 q-py-sm">
            <q-btn class="full-width justify-between" color="process" size="sm" @click="addReglement('iard', 'classique')" icon-right="add_circle">{{$t('processes.add_reglement')}}</q-btn>
          </div>
        </div>
        <h3 class="title-echeancier">{{$t('processes.echeancierPartComplementaire')}}</h3>
        <q-table wrap-cells
          flat
          :data="iardEcheanceComplementaireDatas"
          :columns="iardSanteVieEcheancesColumns"
          row-key="id"
          :rows-per-page-options="[0]"
          :pagination.sync="pagination"
          separator="cell"
          bordered
          hide-bottom>
          <q-tr slot="body" slot-scope="props" :props="props">
            <q-td class="cell-background-white" v-for="col in props.cols" :key="col.name" :props="props" :auto-width="col.autoWidth">
              <div v-if="col.name === 'échéances'">
                <base-input-text :ref="`IARDEcheancierPartComplementaire_${props.row.__index}`"
                  hint="##% sous ## an/ans/mois"
                  :rules="[val => echeanceRegex.test(val) || $t('processes.formatInvalid')]"
                  color="process"
                  @input="customEcheanceComplementaireInputUpdated('IARD', props.row.__index, true)"
                  v-model="currentSuiviIcEcheance.details.IARD.echeances.complementaire[props.row.__index][col.name]" />
              </div>
              <div v-else-if="col.name === 'Statut'">
                <base-input-select :ref="`IARDEcheancierPartComplementaire_select_${props.row.__index}`"
                  :options="echeanceStatusOptions"
                  @input="customEcheanceComplementaireInputUpdated('IARD', props.row.__index, true)"
                  v-model="echeanceComplementaireFormData.IARD[props.row.__index]" />
              </div>
              <div v-else v-html="col.value" />
            </q-td>
          </q-tr>
        </q-table>
        <div class="row justify-end">
          <div class="col col-3 q-py-sm q-px-md q-gutter-sm">
            <q-btn class="full-width justify-between" color="process" size="sm" :disabled="!echeanceComplementaireIardButtonUpdateActive" @click="saveCustomEcheanceComplementaire('IARD')" icon-right="add_circle">{{$t('processes.save')}}</q-btn>
          </div>
        </div>
        <h3 class="title-reglement">{{$t('processes.reglementPartComplementaire')}}</h3>
        <div class="row">
          <div class="col-2">Date</div>
          <div class="col-3">Description</div>
          <div class="col-2">Montant</div>
          <div class="col-2">Intérêts</div>
          <div class="col-2">Pourcentage</div>
          <div class="col-1"></div>
        </div>
        <div v-for="reglement in currentSuiviIcEcheance.details.IARD.reglements.complementaire" :key="reglement.id" class="row col-12 items-end">
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" :color="color"
            :value="$formatDate(reglement.date_paiement)" />
          <base-input-text class="col-3 q-pb-none" label="" :readonly="true" placeholder="Description" :color="color"
            :value="`${reglement.description}`" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="montant" :color="color"
            :value="`${parseFloat(reglement.montant || 0).toLocaleString()}€`" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="interets" :color="color"
            :value="`${parseFloat(reglement.interets || 0).toLocaleString()}€`" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="pourcentage" :color="color"
            :value="`${reglement.pourcentage}%`" />
          <q-icon class="col-1 q-pb-sm" name="delete_forever" @click="removeReglement('IARD', 'complementaire', reglement.id)" size="2rem" />
        </div>
        <div class="row col-12 items-end">
          <div class="fix-padding q-pb-none col-2 q-field--with-bottom">
            <base-input-date label="" placeholder="Date" :color="color"
              v-model="dateReglement.iard.complementaire" />
          </div>
          <base-input-text class="col-3 q-pb-none" label="" placeholder="Description" :color="color"
            v-model="descriptionReglement.iard.complementaire" />
          <base-input-text class="col-2 q-pb-none" label="" placeholder="Montant" :color="color"
            v-model="montantReglement.iard.complementaire" />
          <base-input-text class="col-2 q-pb-none" label="" placeholder="Interet" :color="color"
            v-model="interetReglement.iard.complementaire" />
          <div class="col-1"></div>
        </div>
        <div class="row justify-end">
          <div class="col col-3 q-py-sm">
            <q-btn class="full-width justify-between" color="process" size="sm" @click="addReglement('iard', 'complementaire')" icon-right="add_circle">{{$t('processes.add_reglement')}}</q-btn>
          </div>
        </div>
      </div>
      <div v-if="currentSuiviIcEcheance.details.SANTE">
        <h6 class="title-h6">{{$t('processes.sante')}}</h6>
        <q-table wrap-cells
          flat
          :data="santeDetailsDatas"
          :columns="iardSanteVieDetailsColumns"
          row-key="id"
          :rows-per-page-options="[0]"
          :pagination.sync="pagination"
          separator="cell"
          hide-bottom
          bordered
          hide-header>
          <q-tr slot="body" slot-scope="props" :props="props"
            class="cursor-pointer">
            <q-td v-for="col in props.cols" :key="col.name" :props="props" :auto-width="col.autoWidth">
              <div v-if="col.value" v-html="col.value" />
            </q-td>
          </q-tr>
        </q-table>
        <h3 class="title-echeancier">{{$t('processes.echeancierPartClassique')}}</h3>
        <q-table wrap-cells v-if="echeanceClassiqueFormData.SANTE"
          flat
          :data="santeEcheanceClassiqueDatas"
          :columns="iardSanteVieEcheancesColumns"
          row-key="id"
          :rows-per-page-options="[0]"
          :pagination.sync="pagination"
          separator="cell"
          bordered
          hide-bottom>
          <q-tr slot="body" slot-scope="props" :props="props">
            <q-td class="cell-background-white" v-for="col in props.cols" :key="col.name" :props="props" :auto-width="col.autoWidth">
              <div v-if="col.value" v-html="col.value" />
            </q-td>
          </q-tr>
        </q-table>
        <h3 class="title-reglement">{{$t('processes.reglementPartClassique')}}</h3>
        <div class="row">
          <div class="col-2">Date</div>
          <div class="col-3">Description</div>
          <div class="col-2">Montant</div>
          <div class="col-2">Intérêts</div>
          <div class="col-2">Pourcentage</div>
          <div class="col-1"></div>
        </div>
        <div v-for="reglement in currentSuiviIcEcheance.details.SANTE.reglements.classique" :key="reglement.id" class="row col-12 items-end">
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" :color="color"
            :value="$formatDate(reglement.date_paiement)" />
          <base-input-text class="col-3 q-pb-none" label="" :readonly="true" placeholder="Description" :color="color"
            :value="`${reglement.description || ''}`" ref="label" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="montant" :color="color"
            :value="`${parseFloat(reglement.montant || 0).toLocaleString()}€`" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="interets" :color="color"
            :value="`${parseFloat(reglement.interets || 0).toLocaleString()}€`" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="pourcentage" :color="color"
            :value="`${reglement.pourcentage}%`" />
          <q-icon class="col-1 q-pb-sm" name="delete_forever" @click="removeReglement('SANTE', 'classique', reglement.id)" size="2rem" />
        </div>
        <div class="row col-12 items-end">
          <div class="fix-padding q-pb-none col-2 q-field--with-bottom">
            <base-input-date label="" placeholder="Date" :color="color"
              v-model="dateReglement.sante.classique" />
          </div>
          <base-input-text class="col-3 q-pb-none" label="" placeholder="Description" :color="color"
            v-model="descriptionReglement.sante.classique" />
          <base-input-text class="col-2 q-pb-none" label="" placeholder="Montant" :color="color"
            v-model="montantReglement.sante.classique" />
          <base-input-text class="col-2 q-pb-none" label="" placeholder="Interet" :color="color"
            v-model="interetReglement.sante.classique" />
          <div class="col-1"></div>
        </div>
        <div class="row justify-end">
          <div class="col col-3 q-py-sm">
            <q-btn class="full-width justify-between" color="process" size="sm" @click="addReglement('sante', 'classique')" icon-right="add_circle">{{$t('processes.add_reglement')}}</q-btn>
          </div>
        </div>
        <h3 class="title-echeancier">{{$t('processes.echeancierPartComplementaire')}}</h3>
        <q-table wrap-cells
          flat
          :data="santeEcheanceComplementaireDatas"
          :columns="iardSanteVieEcheancesColumns"
          row-key="id"
          :rows-per-page-options="[0]"
          :pagination.sync="pagination"
          separator="cell"
          bordered
          hide-bottom>
          <q-tr slot="body" slot-scope="props" :props="props">
            <q-td class="cell-background-white" v-for="col in props.cols" :key="col.name" :props="props" :auto-width="col.autoWidth">
              <div v-if="col.name === 'échéances'">
                <base-input-text :ref="`SANTEEcheancierPartComplementaire_${props.row.__index}`"
                  hint="##% sous ## an/ans/mois"
                  :rules="[val => echeanceRegex.test(val) || $t('processes.formatInvalid')]"
                  color="process"
                  @input="customEcheanceComplementaireInputUpdated('SANTE', props.row.__index, true)"
                  v-model="currentSuiviIcEcheance.details.SANTE.echeances.complementaire[props.row.__index][col.name]" />
              </div>
              <div v-else-if="col.name === 'Statut'">
                <base-input-select :ref="`SANTEEcheancierPartClassique_select_${props.row.__index}`"
                  :options="echeanceStatusOptions"
                  @input="customEcheanceComplementaireInputUpdated('SANTE', props.row.__index, true)"
                  v-model="echeanceComplementaireFormData.SANTE[props.row.__index]" />
              </div>
              <div v-else v-html="col.value" />
            </q-td>
          </q-tr>
        </q-table>
        <div class="row justify-end">
          <div class="col col-3 q-py-sm q-px-md q-gutter-sm">
            <q-btn class="full-width justify-between" color="process" size="sm" :disabled="!echeanceComplementaireSanteButtonUpdateActive" @click="saveCustomEcheanceComplementaire('SANTE')" icon-right="add_circle">{{$t('processes.save')}}</q-btn>
          </div>
        </div>
        <h3 class="title-reglement">{{$t('processes.reglementPartComplementaire')}}</h3>
        <div class="row">
          <div class="col-2">Date</div>
          <div class="col-3">Description</div>
          <div class="col-2">Montant</div>
          <div class="col-2">Intérêts</div>
          <div class="col-2">Pourcentage</div>
          <div class="col-1"></div>
        </div>
        <div v-for="reglement in currentSuiviIcEcheance.details.SANTE.reglements.complementaire" :key="reglement.id" class="row col-12 items-end">
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" :color="color"
            :value="$formatDate(reglement.date_paiement)" />
          <base-input-text class="col-3 q-pb-none" label="" :readonly="true" placeholder="Description" :color="color"
            :value="`${reglement.description || ''}`" ref="label" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="montant" :color="color"
            :value="`${parseFloat(reglement.montant || 0).toLocaleString()}€`" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="interets" :color="color"
            :value="`${parseFloat(reglement.interets || 0).toLocaleString()}€`" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="pourcentage" :color="color"
            :value="`${reglement.pourcentage}%`" />
          <q-icon class="col-1 q-pb-sm" name="delete_forever" @click="removeReglement('SANTE', 'complementaire', reglement.id)" size="2rem" />
        </div>
        <div class="row col-12 items-end">
          <div class="fix-padding q-pb-none col-2 q-field--with-bottom">
            <base-input-date label="" placeholder="Date" :color="color"
              v-model="dateReglement.sante.complementaire" />
          </div>
          <base-input-text class="col-3 q-pb-none" label="" placeholder="Description" :color="color"
            v-model="descriptionReglement.sante.complementaire" />
          <base-input-text class="col-2 q-pb-none" label="" placeholder="Montant" :color="color"
            v-model="montantReglement.sante.complementaire" />
          <base-input-text class="col-2 q-pb-none" label="" placeholder="Interet" :color="color"
            v-model="interetReglement.sante.complementaire" />
          <div class="col-1"></div>
        </div>
        <div class="row justify-end">
          <div class="col col-3 q-py-sm">
            <q-btn class="full-width justify-between" color="process" size="sm" @click="addReglement('sante', 'complementaire')" icon-right="add_circle">{{$t('processes.add_reglement')}}</q-btn>
          </div>
        </div>
      </div>
      <div v-if="currentSuiviIcEcheance.details.IC_VIE">
        <h6 class="title-h6">{{$t('processes.vie')}}</h6>
        <q-table wrap-cells
          flat
          :data="vieDetailsDatas"
          :columns="iardSanteVieDetailsColumns"
          row-key="id"
          :rows-per-page-options="[0]"
          :pagination.sync="pagination"
          separator="cell"
          hide-bottom
          bordered
          hide-header>
          <q-tr slot="body" slot-scope="props" :props="props"
            class="cursor-pointer">
            <q-td v-for="col in props.cols" :key="col.name" :props="props" :auto-width="col.autoWidth">
              <div v-if="col.value" v-html="col.value" />
            </q-td>
          </q-tr>
        </q-table>
        <h3 class="title-echeancier">{{$t('processes.echeancierPartClassique')}}</h3>
        <q-table wrap-cells v-if="echeanceClassiqueFormData.IC_VIE"
          flat
          :data="vieEcheanceClassiqueDatas"
          :columns="iardSanteVieEcheancesColumns"
          row-key="id"
          :rows-per-page-options="[0]"
          :pagination.sync="pagination"
          separator="cell"
          bordered
          hide-bottom>
          <q-tr slot="body" slot-scope="props" :props="props">
            <q-td class="cell-background-white" v-for="col in props.cols" :key="col.name" :props="props" :auto-width="col.autoWidth">
              <div v-if="col.value" v-html="col.value" />
            </q-td>
          </q-tr>
        </q-table>
        <h3 class="title-reglement">{{$t('processes.reglementPartClassique')}}</h3>
        <div class="row">
          <div class="col-2">Date</div>
          <div class="col-3">Description</div>
          <div class="col-2">Montant</div>
          <div class="col-2">Intérêts</div>
          <div class="col-2">Pourcentage</div>
          <div class="col-1"></div>
        </div>
        <div v-for="reglement in currentSuiviIcEcheance.details.IC_VIE.reglements.classique" :key="reglement.id" class="row col-12 items-end">
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" :color="color"
            :value="$formatDate(reglement.date_paiement)" />
          <base-input-text class="col-3 q-pb-none" label="" :readonly="true" placeholder="Description" :color="color"
            :value="`${reglement.description || ''}`" ref="label" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="montant" :color="color"
            :value="`${parseFloat(reglement.montant || 0).toLocaleString()}€`" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="interets" :color="color"
            :value="`${parseFloat(reglement.interets || 0).toLocaleString()}€`" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="pourcentage" :color="color"
            :value="`${reglement.pourcentage}%`" />
          <q-icon class="col-1 q-pb-sm" name="delete_forever" @click="removeReglement('IC_VIE', 'classique', reglement.id)" size="2rem" />
        </div>
        <div class="row col-12 items-end">
          <div class="fix-padding q-pb-none col-2 q-field--with-bottom">
            <base-input-date label="" placeholder="Date" :color="color"
              v-model="dateReglement.ic_vie.classique" />
          </div>
          <base-input-text class="col-3 q-pb-none" label="" placeholder="Description" :color="color"
            v-model="descriptionReglement.ic_vie.classique" />
          <base-input-text class="col-2 q-pb-none" label="" placeholder="Montant" :color="color"
            v-model="montantReglement.ic_vie.classique" />
          <base-input-text class="col-2 q-pb-none" label="" placeholder="Interet" :color="color"
            v-model="interetReglement.ic_vie.classique" />
          <div class="col-1"></div>
        </div>
        <div class="row justify-end">
          <div class="col col-3 q-py-sm">
            <q-btn class="full-width justify-between" color="process" size="sm" @click="addReglement('ic_vie', 'classique')" icon-right="add_circle">{{$t('processes.add_reglement')}}</q-btn>
          </div>
        </div>
        <h3 class="title-echeancier">{{$t('processes.echeancierPartComplementaire')}}</h3>
        <q-table wrap-cells
          flat
          :data="vieEcheanceComplementaireDatas"
          :columns="iardSanteVieEcheancesColumns"
          row-key="id"
          :rows-per-page-options="[0]"
          :pagination.sync="pagination"
          separator="cell"
          bordered
          hide-bottom>
          <q-tr slot="body" slot-scope="props" :props="props">
            <q-td class="cell-background-white" v-for="col in props.cols" :key="col.name" :props="props" :auto-width="col.autoWidth">
              <div v-if="col.name === 'échéances'">
                <base-input-text :ref="`IC_VIEEcheancierPartComplementaire_${props.row.__index}`"
                  hint="##% sous ## an/ans/mois"
                  :rules="[val => echeanceRegex.test(val) || $t('processes.formatInvalid')]"
                  color="process"
                  @input="customEcheanceComplementaireInputUpdated('IC_VIE', props.row.__index, true)"
                  v-model="currentSuiviIcEcheance.details.IC_VIE.echeances.complementaire[props.row.__index][col.name]" />
              </div>
              <div v-else-if="col.name === 'Statut'">
                <base-input-select :ref="`IC_VIEEcheancierPartClassique_select_${props.row.__index}`"
                  :options="echeanceStatusOptions"
                  @input="customEcheanceComplementaireInputUpdated('IC_VIE', props.row.__index, true)"
                  v-model="echeanceComplementaireFormData.IC_VIE[props.row.__index]" />
              </div>
              <div v-else v-html="col.value" />
            </q-td>
          </q-tr>
        </q-table>
        <div class="row justify-end">
          <div class="col col-3 q-py-sm q-px-md q-gutter-sm">
            <q-btn class="full-width justify-between" color="process" size="sm" :disabled="!echeanceComplementaireVieButtonUpdateActive" @click="saveCustomEcheanceComplementaire('IC_VIE')" icon-right="add_circle">{{$t('processes.save')}}</q-btn>
          </div>
        </div>
        <h3 class="title-reglement">{{$t('processes.reglementPartComplementaire')}}</h3>
        <div class="row">
          <div class="col-2">Date</div>
          <div class="col-3">Description</div>
          <div class="col-2">Montant</div>
          <div class="col-2">Intérêts</div>
          <div class="col-2">Pourcentage</div>
          <div class="col-1"></div>
        </div>
        <div v-for="reglement in currentSuiviIcEcheance.details.IC_VIE.reglements.complementaire" :key="reglement.id" class="row col-12 items-end">
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" :color="color"
            :value="$formatDate(reglement.date_paiement)" />
          <base-input-text class="col-3 q-pb-none" label="" :readonly="true" placeholder="Description" :color="color"
            :value="`${reglement.description || ''}`" ref="label" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="montant" :color="color"
            :value="`${parseFloat(reglement.montant || 0).toLocaleString()}€`" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="interets" :color="color"
            :value="`${parseFloat(reglement.interets || 0).toLocaleString()}€`" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="pourcentage" :color="color"
            :value="`${reglement.pourcentage}%`" />
          <q-icon class="col-1 q-pb-sm" name="delete_forever" @click="removeReglement('IC_VIE', 'complementaire', reglement.id)" size="2rem" />
        </div>
        <div class="row col-12 items-end">
          <div class="fix-padding q-pb-none col-2 q-field--with-bottom">
            <base-input-date label="" placeholder="Date" :color="color"
              v-model="dateReglement.ic_vie.complementaire" />
          </div>
          <base-input-text class="col-3 q-pb-none" label="" placeholder="Description" :color="color"
            v-model="descriptionReglement.ic_vie.complementaire" />
          <base-input-text class="col-2 q-pb-none" label="" placeholder="Montant" :color="color"
            v-model="montantReglement.ic_vie.complementaire" />
          <base-input-text class="col-2 q-pb-none" label="" placeholder="Interet" :color="color"
            v-model="interetReglement.ic_vie.complementaire" />
          <div class="col-1"></div>
        </div>
        <div class="row justify-end">
          <div class="col col-3 q-py-sm">
            <q-btn class="full-width justify-between" color="process" size="sm" @click="addReglement('ic_vie', 'complementaire')" icon-right="add_circle">{{$t('processes.add_reglement')}}</q-btn>
          </div>
        </div>
      </div>
      <div v-if="currentSuiviIcEcheance.details.DIFFERENTIEL_IC">
        <h6 class="title-h6">{{$t('processes.differentiel_ic')}}</h6>
        <q-table wrap-cells
          flat
          :data="differentiel_icDetailsDatas"
          :columns="differentiel_icSinistreDetailsColumns"
          row-key="id"
          :rows-per-page-options="[0]"
          :pagination.sync="pagination"
          separator="cell"
          hide-bottom
          bordered
          hide-header>
          <q-tr slot="body" slot-scope="props" :props="props"
            class="cursor-pointer">
            <q-td v-for="col in props.cols" :key="col.name" :props="props" :auto-width="col.autoWidth">
              <div v-if="col.value" v-html="col.value" />
            </q-td>
          </q-tr>
        </q-table>
        <h3 class="title-reglement">{{$t('processes.reglement')}}</h3>
        <div class="row">
          <div class="col-2">Date</div>
          <div class="col-3">Description</div>
          <div class="col-2">Montant</div>
          <div class="col-2">Intérêts</div>
          <div class="col-2">Pourcentage</div>
          <div class="col-1"></div>
        </div>
        <div v-for="reglement in currentSuiviIcEcheance.details.DIFFERENTIEL_IC.reglements.classique" :key="reglement.id" class="row col-12 items-end">
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" :color="color"
            :value="$formatDate(reglement.date_paiement)" />
          <base-input-text class="col-3 q-pb-none" label="" :readonly="true" placeholder="Description" :color="color"
            :value="`${reglement.description || ''}`" ref="label" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="montant" :color="color"
            :value="`${parseFloat(reglement.montant || 0).toLocaleString()}€`" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="interets" :color="color"
            :value="`${parseFloat(reglement.interets || 0).toLocaleString()}€`" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="pourcentage" :color="color"
            :value="`${reglement.pourcentage}%`" />
          <q-icon class="col-1 q-pb-sm" name="delete_forever" @click="removeReglement('DIFFERENTIEL_IC', 'classique', reglement.id)" size="2rem" />
        </div>
        <div class="row col-12 items-end">
          <div class="fix-padding q-pb-none col-2 q-field--with-bottom">
            <base-input-date label="" placeholder="Date" :color="color"
              v-model="dateReglement.differentiel_ic.classique" />
          </div>
          <base-input-text class="col-3 q-pb-none" label="" placeholder="Description" :color="color"
            v-model="descriptionReglement.differentiel_ic.classique" />
          <base-input-text class="col-2 q-pb-none" label="" placeholder="Montant" :color="color"
            v-model="montantReglement.differentiel_ic.classique" />
          <base-input-text class="col-2 q-pb-none" label="" placeholder="Interet" :color="color"
            v-model="interetReglement.differentiel_ic.classique" />
          <div class="col-1"></div>
        </div>
        <div class="row justify-end">
          <div class="col col-3 q-py-sm">
            <q-btn class="full-width justify-between" color="process" size="sm" @click="addReglement('differentiel_ic', 'classique')" icon-right="add_circle">{{$t('processes.add_reglement')}}</q-btn>
          </div>
        </div>
      </div>
      <div v-if="currentSuiviIcEcheance.details.SINISTRE_REORGANISATION">
        <h6 class="title-h6">{{$t('processes.sinistre_reorganisation')}}</h6>
        <q-table wrap-cells
          flat
          :data="sinistre_reorganisationDetailsDatas"
          :columns="differentiel_icSinistreDetailsColumns"
          row-key="id"
          :rows-per-page-options="[0]"
          :pagination.sync="pagination"
          separator="cell"
          hide-bottom
          bordered
          hide-header>
          <q-tr slot="body" slot-scope="props" :props="props"
            class="cursor-pointer">
            <q-td v-for="col in props.cols" :key="col.name" :props="props" :auto-width="col.autoWidth">
              <div v-if="col.value" v-html="col.value" />
            </q-td>
          </q-tr>
        </q-table>
        <h3 class="title-reglement">{{$t('processes.reglement')}}</h3>
        <div class="row">
          <div class="col-2">Date</div>
          <div class="col-3">Description</div>
          <div class="col-2">Montant</div>
          <div class="col-2">Intérêts</div>
          <div class="col-2">Pourcentage</div>
          <div class="col-1"></div>
        </div>
        <div v-for="reglement in currentSuiviIcEcheance.details.SINISTRE_REORGANISATION.reglements.classique" :key="reglement.id" class="row col-12 items-end">
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" :color="color"
            :value="$formatDate(reglement.date_paiement)" />
          <base-input-text class="col-3 q-pb-none" label="" :readonly="true" placeholder="Description" :color="color"
            :value="`${reglement.description || ''}`" ref="label" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="montant" :color="color"
            :value="`${parseFloat(reglement.montant || 0).toLocaleString()}€`" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="interets" :color="color"
            :value="`${parseFloat(reglement.interets || 0).toLocaleString()}€`" />
          <base-input-text class="col-2 q-pb-none" label="" :readonly="true" placeholder="pourcentage" :color="color"
            :value="`${reglement.pourcentage}%`" />
          <q-icon class="col-1 q-pb-sm" name="delete_forever" @click="removeReglement('SINISTRE_REORGANISATION', 'classique', reglement.id)" size="2rem" />
        </div>
        <div class="row col-12 items-end">
          <div class="fix-padding q-pb-none col-2 q-field--with-bottom">
            <base-input-date label="" placeholder="Date" :color="color"
              v-model="dateReglement.sinistre_reorganisation.classique" />
          </div>
          <base-input-text class="col-3 q-pb-none" label="" placeholder="Description" :color="color"
            v-model="descriptionReglement.sinistre_reorganisation.classique" />
          <base-input-text class="col-2 q-pb-none" label="" placeholder="Montant" :color="color"
            v-model="montantReglement.sinistre_reorganisation.classique" />
          <base-input-text class="col-2 q-pb-none" label="" placeholder="Interet" :color="color"
            v-model="interetReglement.sinistre_reorganisation.classique" />
          <div class="col-1"></div>
        </div>
        <div class="row justify-end">
          <div class="col col-3 q-py-sm">
            <q-btn class="full-width justify-between" color="process" size="sm" @click="addReglement('sinistre_reorganisation', 'classique')" icon-right="add_circle">{{$t('processes.add_reglement')}}</q-btn>
          </div>
        </div>
      </div>
    </div>

    <div class="col col-2 q-gutter-y-sm">
      <div class="q-gutter-y-sm">
        <q-btn :color="color" class="full-width q-py-md" size="sm" @click="finish">{{$t('form.finish')}}</q-btn>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  mixins: [],
  components: { },
  props: {
    'form_model': { type: String, default: 'processes' },
    'action': { type: String, default: 'processes/saveProcesses' }
  },

  data () {
    return {
      filter: {},
      isUpdate: this.$route.params.id,
      pagination: {
        page: 1,
        rowsPerPage: 0
      },
      dateReglement: { iard: {}, sante: {}, ic_vie: {}, differentiel_ic: {}, sinistre_reorganisation: {} },
      descriptionReglement: { iard: {}, sante: {}, ic_vie: {}, differentiel_ic: {}, sinistre_reorganisation: {} },
      montantReglement: { iard: {}, sante: {}, ic_vie: {}, differentiel_ic: {}, sinistre_reorganisation: {} },
      interetReglement: { iard: {}, sante: {}, ic_vie: {}, differentiel_ic: {}, sinistre_reorganisation: {} },
      echeanceRegex: /^([0-9]|[1-9][0-9]|[1-9][0-9]0)(\.([1-9]|[0-9][0-9]))?% sous ([0-9]|[1-9][0-9]) (mois|ans?)$/,
      echeanceComplementaireIardButtonUpdateActive: false,
      echeanceComplementaireSanteButtonUpdateActive: false,
      echeanceComplementaireVieButtonUpdateActive: false,
      echeanceClassiqueFormData: {
        IARD: null,
        SANTE: null,
        IC_VIE: null
      },
      echeanceComplementaireFormData: {
        IARD: null,
        SANTE: null,
        IC_VIE: null
      },
      echeanceStatusOptions: [
        { label: 'EN COURS', value: 'EN COURS' },
        { label: 'KO', value: 'KO' },
        { label: 'OK', value: 'OK' },
        { label: 'OK (hors période)', value: 'OK_HORS_PERIODE' }
      ]
    }
  },

  watch: {
    currentSuiviIcEcheance: {
      handler (newVal, oldVal) {
        if (newVal && newVal.details && newVal.details.IARD && newVal.details.IARD.echeances.classique && !this.echeanceClassiqueFormData.IARD) this.echeanceClassiqueFormData.IARD = newVal.details.IARD.echeances.classique.map(echeance => this.echeanceStatusOptions.find(ech => ech.label === echeance.Statut))
        if (newVal && newVal.details && newVal.details.SANTE && newVal.details.SANTE.echeances.classique && !this.echeanceClassiqueFormData.SANTE) this.echeanceClassiqueFormData.SANTE = newVal.details.SANTE.echeances.classique.map(echeance => this.echeanceStatusOptions.find(ech => ech.label === echeance.Statut))
        if (newVal && newVal.details && newVal.details.IC_VIE && newVal.details.IC_VIE.echeances.classique && !this.echeanceClassiqueFormData.IC_VIE) this.echeanceClassiqueFormData.IC_VIE = newVal.details.IC_VIE.echeances.classique.map(echeance => this.echeanceStatusOptions.find(ech => ech.label === echeance.Statut))

        if (newVal && newVal.details && newVal.details.IARD && newVal.details.IARD.echeances.complementaire && !this.echeanceComplementaireFormData.IARD) this.echeanceComplementaireFormData.IARD = newVal.details.IARD.echeances.complementaire.map(echeance => this.echeanceStatusOptions.find(ech => ech.label === echeance.Statut))
        if (newVal && newVal.details && newVal.details.SANTE && newVal.details.SANTE.echeances.complementaire && !this.echeanceComplementaireFormData.SANTE) this.echeanceComplementaireFormData.SANTE = newVal.details.SANTE.echeances.complementaire.map(echeance => this.echeanceStatusOptions.find(ech => ech.label === echeance.Statut))
        if (newVal && newVal.details && newVal.details.IC_VIE && newVal.details.IC_VIE.echeances.complementaire && !this.echeanceComplementaireFormData.IC_VIE) this.echeanceComplementaireFormData.IC_VIE = newVal.details.IC_VIE.echeances.complementaire.map(echeance => this.echeanceStatusOptions.find(ech => ech.label === echeance.Statut))
      },
      deep: true,
      immediate: true
    },
    'currentSuiviIcEcheance.details': {
      handler (newVal, oldVal) {
        // Need to put in nextTick because input state is updated after value updated
        this.$nextTick(() => {
          if (newVal && newVal.IARD && this.isCustomEcheanceComplementaireUpdated('IARD')) this.echeanceComplementaireIardButtonUpdateActive = !this.isCustomEcheanceComplementaireHasError('IARD')
          if (newVal && newVal.SANTE && this.isCustomEcheanceComplementaireUpdated('SANTE')) this.echeanceComplementaireSanteButtonUpdateActive = !this.isCustomEcheanceComplementaireHasError('SANTE')
          if (newVal && newVal.IC_VIE && this.isCustomEcheanceComplementaireUpdated('IC_VIE')) this.echeanceComplementaireVieButtonUpdateActive = !this.isCustomEcheanceComplementaireHasError('IC_VIE')
        })
      },
      deep: true
    },
    echeanceComplementaireFormData: {
      handler (newVal, oldVal) {
        if (newVal && newVal.IARD && this.isCustomEcheanceComplementaireUpdated('IARD')) this.echeanceComplementaireIardButtonUpdateActive = true
        if (newVal && newVal.SANTE && this.isCustomEcheanceComplementaireUpdated('SANTE')) this.echeanceComplementaireSanteButtonUpdateActive = true
        if (newVal && newVal.IC_VIE && this.isCustomEcheanceComplementaireUpdated('IC_VIE')) this.echeanceComplementaireVieButtonUpdateActive = true
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters({
      color: 'pages/getColor',
      isAdmin: 'auth/isAdmin',
      currentSuiviIcEcheance: 'processes/getCurrentProcess',
      rowsPerPageOptions: 'config/getRowsPerPageOptions'
    }),
    syntheseDatas () {
      if (!this.currentSuiviIcEcheance || !this.currentSuiviIcEcheance.synthèse) return []
      let result = Object.keys(this.currentSuiviIcEcheance.synthèse).map(domaine => ({
        'domaine': domaine,
        'montant': this.currentSuiviIcEcheance.synthèse[domaine].montant,
        'payé': this.currentSuiviIcEcheance.synthèse[domaine].payé,
        'dû': this.currentSuiviIcEcheance.synthèse[domaine].dû,
        'accepté_agent': this.currentSuiviIcEcheance.synthèse[domaine].accepté_agent
      }))

      result.push({
        domaine: 'TOTAL',
        montant: `${result.reduce((accumulator, currentValue) => accumulator + Number(currentValue.montant.replace(/€/g, '').replace(/ /g, '')), 0).toLocaleString()}€`,
        payé: `${result.reduce((accumulator, currentValue) => accumulator + Number(currentValue.payé.replace(/€/g, '').replace(/ /g, '')), 0).toLocaleString()}€`,
        dû: `${result.reduce((accumulator, currentValue) => accumulator + Number(currentValue.dû.replace(/€/g, '').replace(/ /g, '')), 0).toLocaleString()}€`,
        accepté_agent: ''
      })

      return result
    },
    syntheseColumns () {
      if (!this.currentSuiviIcEcheance || !this.currentSuiviIcEcheance.synthèse) return []
      else {
        const fields = ['domaine', 'montant', 'payé', 'dû', 'accepté_agent']

        return fields.map(key => {
          let label = key.toUpperCase()
          let align = 'left'
          let autoWidth = false
          let formatFn = val => val

          if (key === 'domaine') {
            label = ''
            formatFn = val => val.replace(/_/g, ' ')
          } else if (key === 'montant' || key === 'payé' || key === 'dû') {
            formatFn = val => val
          } else if (key === 'accepté_agent') {
            label = 'état'.toUpperCase()
            formatFn = val => val === '' ? '' : val ? 'Validé agent' : 'Non validé agent'
          }

          return {
            name: key,
            align: align,
            label: label,
            field: key,
            format: formatFn,
            sortable: false,
            autoWidth: autoWidth
          }
        })
      }
    },
    iardSanteVieDetailsColumns () {
      if (!this.currentSuiviIcEcheance || !this.currentSuiviIcEcheance.details || !this.currentSuiviIcEcheance.details.IARD || !this.currentSuiviIcEcheance.details.IARD.details) return []
      else {
        const fields = ['montant', 'classique', 'complément', 'accepté_agent']

        return fields.map(key => {
          let label = ''
          let align = 'left'
          let autoWidth = false
          let formatFn = val => val

          if (key === 'montant') {
            formatFn = val => {
              return `<strong>Montant validé : ${val.montant_validé}<br />Réglé : ${val.Réglé}</strong>`
            }
          } else if (key === 'classique') {
            formatFn = val => {
              return `Part classique : ${val.Part_classique}<br />Réglé : ${val.Part_classique_réglé}`
            }
          } else if (key === 'complément') {
            formatFn = val => {
              return `Part complémentaire : ${val.Part_complementaire}<br />Réglé : ${val.Part_complementaire_réglé}`
            }
          } else if (key === 'accepté_agent') {
            formatFn = val => val ? 'Validé agent' : 'Non validé agent'
          }

          return {
            name: key,
            align: align,
            label: label,
            field: key,
            format: formatFn,
            sortable: false,
            autoWidth: autoWidth
          }
        })
      }
    },
    iardSanteVieEcheancesColumns () {
      if (!this.currentSuiviIcEcheance || !this.currentSuiviIcEcheance.details || !this.currentSuiviIcEcheance.details.IARD || !this.currentSuiviIcEcheance.details.IARD.details) return []
      else {
        const fields = ['échéances', 'Montants', 'Dates limites', 'Payé dans la période', 'Total réglé', 'Intérêts', 'Statut']

        return fields.map(key => {
          let label = key
          let align = 'left'
          let autoWidth = false
          let formatFn = val => val

          if (key === 'Dates limites') {
            formatFn = val => this.$formatDate(val)
          }

          return {
            name: key,
            align: align,
            label: label,
            field: key,
            format: formatFn,
            sortable: false,
            autoWidth: autoWidth
          }
        })
      }
    },
    // IARD
    iardDetailsDatas () {
      return this.getComputedAmount('IARD')
    },
    iardEcheanceClassiqueDatas () {
      if (!this.currentSuiviIcEcheance || !this.currentSuiviIcEcheance.details || !this.currentSuiviIcEcheance.details.IARD) return []
      return this.currentSuiviIcEcheance.details.IARD.echeances.classique.map((echeance, index) => {
        const payeDansLaPeriodeFromApi = echeance['Payé dans la période'] ? Number(echeance['Payé dans la période'].split(' ').join('').split('€')[0]) : 0
        const echeanceSplited = Number(echeance['Montants'].split(' ').join('').split('€')[0])
        delete echeance['Payé dans la période']

        const payeDansLaPeriode = index === 0 && payeDansLaPeriodeFromApi > echeanceSplited ? echeanceSplited : payeDansLaPeriodeFromApi

        return {
          ...echeance,
          'Payé dans la période': `${payeDansLaPeriode}€`
        }
      })
    },
    iardEcheanceComplementaireDatas () {
      if (!this.currentSuiviIcEcheance || !this.currentSuiviIcEcheance.details || !this.currentSuiviIcEcheance.details.IARD) return []
      return this.currentSuiviIcEcheance.details.IARD.echeances.complementaire.map((echeance, index) => {
        const payeDansLaPeriodeFromApi = echeance['Payé dans la période'] ? Number(echeance['Payé dans la période'].split(' ').join('').split('€')[0]) : 0
        const echeanceSplited = Number(echeance['Montants'].split(' ').join('').split('€')[0])
        delete echeance['Payé dans la période']

        console.log(payeDansLaPeriodeFromApi, echeanceSplited)
        const payeDansLaPeriode = payeDansLaPeriodeFromApi > echeanceSplited ? echeanceSplited : payeDansLaPeriodeFromApi

        return {
          ...echeance,
          'Payé dans la période': `${payeDansLaPeriode}€`
        }
      })
    },
    // SANTE
    santeDetailsDatas () {
      return this.getComputedAmount('SANTE')
    },
    santeEcheanceClassiqueDatas () {
      if (!this.currentSuiviIcEcheance || !this.currentSuiviIcEcheance.details || !this.currentSuiviIcEcheance.details.SANTE) return []
      return this.currentSuiviIcEcheance.details.SANTE.echeances.classique.map((echeance, index) => {
        const payeDansLaPeriodeFromApi = echeance['Payé dans la période'] ? Number(echeance['Payé dans la période'].split(' ').join('').split('€')[0]) : 0
        const echeanceSplited = Number(echeance['Montants'].split(' ').join('').split('€')[0])
        delete echeance['Payé dans la période']

        const payeDansLaPeriode = index === 0 && payeDansLaPeriodeFromApi > echeanceSplited ? echeanceSplited : payeDansLaPeriodeFromApi

        return {
          ...echeance,
          'Payé dans la période': `${payeDansLaPeriode}€`
        }
      })
    },
    santeEcheanceComplementaireDatas () {
      console.log(this.currentSuiviIcEcheance.details.SANTE.echeances.complementaire)
      if (!this.currentSuiviIcEcheance || !this.currentSuiviIcEcheance.details || !this.currentSuiviIcEcheance.details.SANTE) return []
      return this.currentSuiviIcEcheance.details.SANTE.echeances.complementaire.map((echeance, index) => {
        const payeDansLaPeriodeFromApi = echeance['Payé dans la période'] ? Number(echeance['Payé dans la période'].split(' ').join('').split('€')[0]) : 0
        const echeanceSplited = Number(echeance['Montants'].split(' ').join('').split('€')[0])
        delete echeance['Payé dans la période']

        const payeDansLaPeriode = payeDansLaPeriodeFromApi > echeanceSplited ? echeanceSplited : payeDansLaPeriodeFromApi

        return {
          ...echeance,
          'Payé dans la période': `${payeDansLaPeriode}€`
        }
      })
    },
    // VIE
    vieDetailsDatas () {
      return this.getComputedAmount('IC_VIE')
    },
    vieEcheanceClassiqueDatas () {
      if (!this.currentSuiviIcEcheance || !this.currentSuiviIcEcheance.details || !this.currentSuiviIcEcheance.details.IC_VIE) return []
      return this.currentSuiviIcEcheance.details.IC_VIE.echeances.classique.map((echeance, index) => {
        const payeDansLaPeriodeFromApi = echeance['Payé dans la période'] ? Number(echeance['Payé dans la période'].split(' ').join('').split('€')[0]) : 0
        const echeanceSplited = Number(echeance['Montants'].split(' ').join('').split('€')[0])
        delete echeance['Payé dans la période']

        const payeDansLaPeriode = index === 0 && payeDansLaPeriodeFromApi > echeanceSplited ? echeanceSplited : payeDansLaPeriodeFromApi

        return {
          ...echeance,
          'Payé dans la période': `${payeDansLaPeriode}€`
        }
      })
    },
    vieEcheanceComplementaireDatas () {
      if (!this.currentSuiviIcEcheance || !this.currentSuiviIcEcheance.details || !this.currentSuiviIcEcheance.details.IC_VIE) return []
      return this.currentSuiviIcEcheance.details.IC_VIE.echeances.complementaire.map((echeance, index) => {
        const payeDansLaPeriodeFromApi = echeance['Payé dans la période'] ? Number(echeance['Payé dans la période'].split(' ').join('').split('€')[0]) : 0
        const echeanceSplited = Number(echeance['Montants'].split(' ').join('').split('€')[0])
        delete echeance['Payé dans la période']

        const payeDansLaPeriode = payeDansLaPeriodeFromApi > echeanceSplited ? echeanceSplited : payeDansLaPeriodeFromApi

        return {
          ...echeance,
          'Payé dans la période': `${payeDansLaPeriode}€`
        }
      })
    },
    // Differentiel IC + Sinistre
    differentiel_icSinistreDetailsColumns () {
      if (!this.currentSuiviIcEcheance || !this.currentSuiviIcEcheance.details) return []
      else {
        const fields = ['montant', 'classique', 'accepté_agent']

        return fields.map(key => {
          let label = ''
          let align = 'left'
          let autoWidth = false
          let formatFn = val => val

          if (key === 'montant') {
            formatFn = val => {
              return `<strong>Montant validé : ${val.montant_validé}<br />Réglé : ${val.Réglé}</strong>`
            }
          } else if (key === 'classique') {
            formatFn = val => {
              return `Par classique : ${val.Part_classique}<br />Réglé : ${val.Part_classique_réglé}`
            }
          } else if (key === 'accepté_agent') {
            formatFn = val => val ? 'Validé agent' : 'Non validé agent'
          }

          return {
            name: key,
            align: align,
            label: label,
            field: key,
            format: formatFn,
            sortable: false,
            autoWidth: autoWidth
          }
        })
      }
    },
    differentiel_icDetailsDatas () {
      return this.getComputedAmount('DIFFERENTIEL_IC')
    },
    sinistre_reorganisationDetailsDatas () {
      return this.getComputedAmount('SINISTRE_REORGANISATION')
    }
  },

  mounted () {
    this.$store.commit('pages/setTabs', [
      { name: 'suivi-des-ic-form', label: 'Informations', to: { name: 'suivi-des-ic-form', params: { id: this.$route.params.suiviDesIcId } } },
      { name: 'echeance-fiche', label: 'échéances & paiements', to: { name: 'echeance-fiche', params: { suiviDesIcId: this.$route.params.suiviDesIcId } } }
    ])
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('processes.processAgence'), to: { name: '' } },
      { label: this.$t('processes.title.suivi_des_ic'), to: { name: 'suivi-des-ic-en-cours' } },
      this.$route.params.suiviDesIcId ? { label: `fiche #${this.$route.params.suiviDesIcId}`, to: { name: 'suivi-des-ic-form', params: { id: this.$route.params.suiviDesIcId } } } : {}
    ])
    this.$store.commit('pages/setPageTitle', this.$t('processes.title.suivi_des_ic'))
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (currentRoute.params.suiviDesIcId) {
      store.dispatch('processes/fetchEcheance', { suiviDesIcId: currentRoute.params.suiviDesIcId })
    }
  },
  beforeDestroy () {
    this.$store.commit('processes/setCurrentProcess', null)
  },
  methods: {
    customEcheanceComplementaireInputUpdated (model, index, val = true) {
      this.currentSuiviIcEcheance.details[model].echeances.complementaire[index].echeancesUpdated = val
    },
    isCustomEcheanceComplementaireUpdated (model) {
      if (!this.currentSuiviIcEcheance.details[model] || !this.currentSuiviIcEcheance.details[model].echeances || !this.currentSuiviIcEcheance.details[model].echeances.complementaire) return false
      for (let index = 0; index < this.currentSuiviIcEcheance.details[model].echeances.complementaire.length; index++) {
        if (this.currentSuiviIcEcheance.details[model].echeances.complementaire[index].echeancesUpdated) return true
      }
      return false
    },
    isCustomEcheanceComplementaireHasError (model) {
      if (!this.currentSuiviIcEcheance.details[model] || !this.currentSuiviIcEcheance.details[model].echeances || !this.currentSuiviIcEcheance.details[model].echeances.complementaire) return false
      for (let index = 0; index < this.currentSuiviIcEcheance.details[model].echeances.complementaire.length; index++) {
        if (this.$refs[`${model}EcheancierPartComplementaire_${index}`][0].hasError) return true
      }
      return false
    },
    saveCustomEcheanceComplementaire (model) {
      this.currentSuiviIcEcheance.details[model].echeances.complementaire.forEach((echeance, index) => {
        if (!echeance.echeancesUpdated) return
        const echeances = echeance['échéances']
        const percent = echeances.substring(0, 2)
        const duration = echeances.substring(9)
        const numberDuration = duration.split(' ')[0]
        const unitDuration = duration.split(' ')[1]
        const numeroEcheance = index + 1
        const statut = this.echeanceComplementaireFormData[model][index].value

        let durationMapped = null
        let unitDurationMapped = null

        switch (unitDuration) {
          case 'mois':
            unitDurationMapped = numberDuration > 1 ? 'months' : 'month'
            break
          case 'an':
            unitDurationMapped = 'year'
            break
          case 'ans':
            unitDurationMapped = 'years'
            break
        }
        durationMapped = `${numberDuration} ${unitDurationMapped}`

        const params = {
          suiviIcId: this.$route.params.suiviDesIcId,
          domaine: model
        }
        if (unitDurationMapped) {
          params[`delai_echeance_${numeroEcheance}`] = durationMapped
          params[`pourcentage_echeance_${numeroEcheance}`] = percent
        }
        params[`status_echeance_${numeroEcheance}`] = statut

        this.$store.dispatch('processes/saveEcheanceRegleCustom', params).then((response) => {
          this.$q.notify({
            message: 'Echéance modifié avec succes',
            color: 'positive'
          })
        })
      })
    },
    saveCustomEcheanceClassique (model) {
      this.echeanceClassiqueFormData[model].forEach((echeance, index) => {
        const statut = echeance.value
        const numeroEcheance = index + 1

        const params = {
          suiviIcId: this.$route.params.suiviDesIcId,
          type_ic: 'classique',
          domaine: model
        }

        params[`status_echeance_${numeroEcheance}`] = statut

        this.$store.dispatch('processes/saveEcheanceRegleCustom', params).then((response) => {
          this.$q.notify({
            message: 'Echéance modifié avec succes',
            color: 'positive'
          })
        })
      })
    },
    finish () {
      this.$router.push({ name: 'suivi-des-ic-form', params: { id: this.$route.params.suiviDesIcId } })
    },
    addReglement (model, typeIc) {
      let modelMappedToDomaine = model.toUpperCase()
      let data = {
        suivi_ic_id: this.$route.params.suiviDesIcId,
        date_paiement: this.dateReglement[model][typeIc],
        montant: this.montantReglement[model][typeIc],
        description: this.descriptionReglement[model][typeIc],
        interets: this.interetReglement[model][typeIc] || 0,
        type_ic: typeIc,
        domaine: modelMappedToDomaine
      }
      if (isNaN(data.montant)) {
        return this.$q.notify({
          message: 'Montant invalide',
          color: 'negative'
        })
      } else if (isNaN(data.interets)) {
        return this.$q.notify({
          message: 'Intérêts invalide',
          color: 'negative'
        })
      }
      this.$store.dispatch('processes/addReglement', data).then((response) => {
        this.$q.notify({
          message: 'Règlement ajouté avec succes',
          color: 'positive'
        })
        this.currentSuiviIcEcheance.details[modelMappedToDomaine].reglements[typeIc].push({ ...data, pourcentage: '-', id: response.id })

        this.dateReglement[model][typeIc] = null
        this.montantReglement[model][typeIc] = null
        this.descriptionReglement[model][typeIc] = null
        this.interetReglement[model][typeIc] = null
      })
    },
    removeReglement (modelMappedToDomaine, typeIc, reglementId) {
      this.$store.dispatch('processes/removeReglement', reglementId).then((response) => {
        this.$q.notify({
          message: 'Règlement supprimé avec succes',
          color: 'positive'
        })
        this.currentSuiviIcEcheance.details[modelMappedToDomaine].reglements[typeIc] =
          this.currentSuiviIcEcheance.details[modelMappedToDomaine].reglements[typeIc].filter(reglement => reglement.id !== reglementId)
      })
    },
    getComputedAmount (model) {
      if (!this.currentSuiviIcEcheance || !this.currentSuiviIcEcheance.details || !this.currentSuiviIcEcheance.details[model] || !this.currentSuiviIcEcheance.details[model].details) return []
      return [{
        montant: {
          montant_validé: this.currentSuiviIcEcheance.details[model].details.montant_validé,
          Réglé: this.currentSuiviIcEcheance.details[model].details.Réglé
        },
        classique: {
          Part_classique: this.currentSuiviIcEcheance.details[model].details.Part_classique,
          Part_classique_réglé: this.currentSuiviIcEcheance.details[model].details.Part_classique_réglé
        },
        complément: {
          Part_complementaire: this.currentSuiviIcEcheance.details[model].details.Part_complementaire,
          Part_complementaire_réglé: this.currentSuiviIcEcheance.details[model].details.Part_complementaire_réglé
        },
        accepté_agent: this.currentSuiviIcEcheance.details[model].details.accepté_agent
      }]
    },
    toggleCollapsible (event) {
      let originalElement = event.target || event.srcElement || event.originalTarget
      if (originalElement.classList.contains('arrow')) {
        originalElement.parentElement.classList.toggle('rotate')
        originalElement.parentElement.nextSibling.classList.toggle('display-none')
      } else {
        originalElement.classList.toggle('rotate')
        originalElement.nextSibling.classList.toggle('display-none')
      }
    },
    onSave ({ id }) {
      this.isUpdate = true
      if (!this.$route.params.id) this.$router.push({ name: 'suivi-des-ic-form', params: { id } })
    },
    destroy () {
      this.$confirm(this.$t('processes.delete_process_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('processes/deleteProcesses', { type: 'suiviIc', id: this.$route.params.id }).then(() => {
          this.notifySuccess('processes.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .title-echeancier
    text-transform none
    opacity .8
    font-size 1.1rem
  .title-reglement
    text-transform none
    opacity .8
    font-size .9rem
  .processColor
    color $process
  .bloc-item
    position relative
  .toggler-collapse
    border 1px solid $process
    border-radius 100%
    width 20px
    height 20px
    position absolute
    right 0
    top 7px
    cursor pointer
  .arrow
    border-top 1px solid $process
    border-left 1px solid $process
    transform rotate(45deg)
    width 6px
    height 6px
    margin-left 6px
    margin-top 7px
  .display-none
    display none
  .rotate
    transform rotate(180deg)
  ul
    list-style: none
    padding: 0
    li
      margin-bottom: 10px
  .button-create-sujet-commit
    height 35px
  .cell-background-white
    background-color #FFF
</style>
